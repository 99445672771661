import { api, param2query } from "./api.service";

export const getAllDomains = (params) => {
  return api.get('logauditoria/listar?' + param2query(params));
};

export const getAllEntidades = () => {
  return api.get('logauditoria/buscar-entidades');
};

export const getHistorico = (id, periodo, entidadeId) => {
  return api.get('logauditoria/historico?' + param2query({
    id, periodo, entidadeId
  }));
};

export const ENTIDADE_LOG_AUDITORIA_ID = 1;
export const ENTIDADE_LOG_ALTERACAO_GUIAS_ID = 2;

export default { getAllDomains };
