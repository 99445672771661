var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "date-picker",
    _vm._b(
      {
        attrs: {
          format: _vm.formato,
          lang: "pt-BR",
          "first-day-of-week": 1,
          clearable: false
        },
        on: { input: _vm.updateValue },
        scopedSlots: _vm._u([
          {
            key: "input",
            fn: function(inputBind) {
              return [
                _c(
                  "input",
                  _vm._g(
                    _vm._b(
                      {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: _vm.mascara,
                            expression: "mascara"
                          }
                        ]
                      },
                      "input",
                      inputBind.props,
                      false
                    ),
                    inputBind.events
                  )
                )
              ]
            }
          }
        ]),
        model: {
          value: _vm.inputValue,
          callback: function($$v) {
            _vm.inputValue = $$v
          },
          expression: "inputValue"
        }
      },
      "date-picker",
      _vm.$props,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }