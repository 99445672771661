<template>
  <date-picker 
    v-model="inputValue"
    :format="formato"
    lang="pt-BR"
    :first-day-of-week="1"
    :clearable="false"
    v-bind="$props"
    @input="updateValue"
  >
    <template v-slot:input="inputBind">
      <input
        v-mask="mascara"
        v-bind="inputBind.props"
        v-on="inputBind.events"
      />
    </template>
  </date-picker>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'date',
    },
    value: {
      type: Date,
      default: null,
    },
  },
  data() {
    return {
      inputValue: this.value,
    };
  },
  computed: {
    mascara() {
      return this.formato.replace(/[a-zA-Z]/g, '#');
    },
    formato() {
      if (this.type == 'month') {
        return 'MM/YYYY';
      }
      return 'DD/MM/YYYY';
    },
  },
  methods: {
    updateValue(newVal) {
      this.$emit('input', newVal);
      this.$emit('change', newVal);
    },
  },
};
</script>
